import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Paho } from 'ng2-mqtt/mqttws31';

import { environment } from '../../../environments/environment';

import { TelemetryUpdate } from './models/telemetry-update';
import { MqttPublishService } from './mqtt-publish.service';
import { MqttService } from './mqtt.service';

import { MqttResponse } from './models/mqtt-response';
import { MqttResultType } from './types/mqtt-result-type';

import { SharedSettingService } from '../shared-setting.service';

@Injectable({
  providedIn: 'root'
})
export class TelemetryUpdateMqttService {
  private cache: BehaviorSubject<MqttResponse<TelemetryUpdate> | null> = new BehaviorSubject<MqttResponse<TelemetryUpdate> | null>(null);

  private publishClient: MqttService;
  private subscribeClient: MqttService;

  private topic: string;

  constructor(
    private mqttPublishService: MqttPublishService,
    private sharedSettingService: SharedSettingService
  ) { }

  /**
   * 開始します.
   */
  async start() {
    if(!this.sharedSettingService.getServicePCFunctionEnabled()){
      // サービスPC機能がOFFの場合、AD車両トピックを設定する
      this.topic = this.sharedSettingService.getVehicle().vin + '/FMS' + environment.setting.telemetryUpdateTopic;
    }else{
      this.topic = this.sharedSettingService.getVehicle().vin + environment.setting.telemetryUpdateTopic;
    }
    this.publishClient = this.mqttPublishService.getMqttClient();

    if (!this.sharedSettingService.getServicePCFunctionEnabled() && !this.sharedSettingService.getAdTestFunctionEnabled()) {
      // サービスPC機能OFFかつAD車両テスト機能がOFFの場合、サブスクライブする
      this.subscribeClient = new MqttService();
      this.subscribeClient.setSubscribe(this.topic, this.onMessageArrived);
      this.subscribeClient.setConnection();
    }
  }

  /**
   * 停止します.
   */
  async stop() {
    this.publishClient = null;

    if (this.subscribeClient) {
      this.subscribeClient.disconnect();
      this.subscribeClient = null;
    }
  }

  /**
   * トピックを取得します.
   */
  getTopic(): string {
    return this.topic;
  }

  /**
   * メッセージを送信します.
   * 
   * @param message 送信メッセージ
   * @returns 送信結果
   */
  publishMessage(message: TelemetryUpdate): boolean {
    if (this.sharedSettingService.getServicePCFunctionEnabled() || this.sharedSettingService.getAdTestFunctionEnabled()) {
      // サービスPC機能がON、もしくはサービスPC機能OFFかつAD車両テスト機能がONの場合、ローカルで保持しているテレメトリを送信する
      this.cache.next({ result: MqttResultType.SUCCESS, message: message });
    }

    if (this.publishClient?.checkConnect()) {
      this.publishClient.sendTopic(JSON.stringify(this.convertServicePC(message)), this.topic);

      return true;
    }

    return false;
  }

  /**
   * コネクションの状態を確認し、接続が切れている場合、再接続します.
   * 
   * @returns 接続状態
   */
  checkAndRetryConnect(): boolean {
    if (this.sharedSettingService.getServicePCFunctionEnabled() || this.sharedSettingService.getAdTestFunctionEnabled()) {
      // サービスPC機能がON、もしくはサービスPC機能OFFかつAD車両テスト機能がONの場合、subscribeClientは再接続しない
      return true;
    }

    if (!this.subscribeClient) {
      return false;
    }

    if (this.subscribeClient.checkConnect()) {
      return true;
    }

    this.subscribeClient.setConnection();

    return false;
  }

  /**
   * コールバック.
   */
  onMessageArrived = (message: Paho.MQTT.Message) => {
    try {
      const messageObject = this.convert(message.payloadString);

      this.cache.next({ result: MqttResultType.SUCCESS, message: messageObject });
    } catch (e) {
      console.error(e);

      this.cache.next({ result: MqttResultType.FAIL, message: null });
    }
  }

  /**
   * テレメトリ受信データのデータ型を変換.
   * AD車両は「taxi_status」以外全てstring型のJSONデータをテレメトリとして送信するため、型変換する.
   * @param json JSONデータ
   * @returns 型変換したTelemetryUpdateデータ
   */
  private convert(json: string): TelemetryUpdate{
    const data = JSON.parse(json) as TelemetryUpdate;
    return {
      create_time: String(data.create_time),
      lat: Number(data.lat),
      lng: Number(data.lng),
      direction: Number(data.direction),
      vin: String(data.vin),
      gpstime: String(data.gpstime),
      speed: Number(data.speed),
      third_right_belt_lock: Number(data.third_right_belt_lock),
      third_left_belt_lock: Number(data.third_left_belt_lock),
      taxi_status: Number(data.taxi_status),
      route_status: String(data.route_status),
      shift_P: Boolean(data.shift_P),
      shift_R: Boolean(data.shift_R),
      shift_N: Boolean(data.shift_N),
      shift_D: Boolean(data.shift_D),
      is_destination: Boolean(data.is_destination),
      soc: Number(data.soc),
      odo: Number(data.odo),
      front_left_door_open: Number(data.front_left_door_open),
      front_right_door_open: Number(data.front_right_door_open),
      rear_left_door_open: Number(data.rear_left_door_open),
      rear_right_door_open: Number(data.rear_right_door_open),
      back_door_open: Number(data.back_door_open),
      door_lock: Number(data.door_lock),
      malfunction: Number(data.malfunction),
      warning: Number(data.warning),
      ad_status: Number(data.ad_status),
      ad_sys_status: Number(data.ad_sys_status),
      ad_arrival: Number(data.ad_arrival),
      route_status_ETA: String(data.route_status_ETA)
    } as TelemetryUpdate;
  }

  /**
   * テレメトリ送信データのデータ型をサービスPCが送信するテレメトリの型に合わせて変換.
   * AD車両は「taxi_status」以外全てstring型のJSONデータをテレメトリとして送信するため、型変換する.
   * @param data データ
   * @returns 型変換したデータ
   */
   private convertServicePC(data: TelemetryUpdate): any{
    const sendData = {};
    Object.keys(data).forEach((key)=>{
      if(this.sharedSettingService.getServicePCFunctionEnabled()){
        // サービスPC機能ONの場合AD車両用のパラメータを含めない
        if(data[key] === null){
          sendData[key] = null;
        }else if(key === "taxi_status"){
          sendData[key] = Number(data[key]);
        }else if(key === "route_status"){
          // サービスPC機能ONの場合"route_status"は常にnull
          sendData[key] = null;
        }else if(key === "rear_left_door_open" || key === "door_lock"){
          // サービスPC機能ONの場合"rear_left_door_open","door_lock"は常に0
          sendData[key] = String(0);
        }else{
          sendData[key] = String(data[key]);
        }
      }else if(this.sharedSettingService.getAdTestFunctionEnabled()){
        if(data[key] === null){
          sendData[key] = null;
        }else if(key === "taxi_status"){
          sendData[key] = Number(data[key]);
        }else if(key === "third_left_belt_lock"){
          sendData["3rd_left_belt_lock"] = String(data[key]);
        }else if(key === "third_right_belt_lock"){
          sendData["3rd_right_belt_lock"] = String(data[key]);
        }else{
          sendData[key] = String(data[key]);
        }
      }
    });

    return sendData;
  }

  /**
   * Observable を取得します.
   */
  message(): Observable<MqttResponse<TelemetryUpdate>> {
    return this.cache.asObservable().pipe(filter((response) => response !== null));
  }

  /**
   * メッセージを作成します.
   * 
   * @param vin vin
   * @returns メッセージ
   */
  createMessage(vin: string): TelemetryUpdate {
    return {
      create_time: '',
      lat: 0.0,
      lng: 0.0,
      direction: 0,
      vin: vin,
      gpstime: '',
      speed: 0,
      third_right_belt_lock: 0,
      third_left_belt_lock: 0,
      taxi_status: 1,
      route_status: null,
      shift_P: true,
      shift_R: false,
      shift_N: false,
      shift_D: false,
      is_destination: false,
      soc: 0,
      odo: 0,
      front_left_door_open: 0,
      front_right_door_open: 0,
      rear_left_door_open: 0,
      rear_right_door_open: 0,
      back_door_open: 0,
      // AD車両テレメトリでは"door_lock"の初期値を3に設定
      door_lock: 3,
      malfunction: 0,
      warning: 0,
      ad_status: 0,
      ad_sys_status: 0,
      ad_arrival: 1,
      route_status_ETA: null,
    } as TelemetryUpdate;
  }
}
