import { Injectable } from '@angular/core';

import { Vehicle } from './servicer/models/vehicle';
import { SharedSetting } from './models/shared-setting';

/**
 * 共有設定を更新/返却します.
 */
@Injectable({
  providedIn: 'root'
})
export class SharedSettingService {
  /** 共有設定. */
  private sharedSetting: SharedSetting = {
    vehicle: null,
    servicePCFunctionEnabled: true,
    adTestFunctionEnabled: false,
    soc: 100
  };

  constructor() {
  }

  /**
   * 共有設定を返却します.
   * 
   * @returns 共有設定
   */
  get(): SharedSetting {
    return this.sharedSetting;
  }

  /**
   * 共有設定を更新します.
   * 
   * @param sharedSetting 共有設定
   */
  set(sharedSetting: SharedSetting) {
    this.sharedSetting = sharedSetting;
  }

  /**
   * 車両情報を返却します.
   * 
   * @returns 車両情報
   */
  getVehicle(): Vehicle {
    return this.sharedSetting.vehicle;
  }

  /**
   * 車両情報を更新します.
   * 
   * @param vehicle 車両情報
   */
  setVehicle(vehicle: Vehicle) {
    this.sharedSetting.vehicle = vehicle;
  }

  /**
   * サービスPC機能の使用を返却します.
   * 
   * @returns サービスPC機能の使用
   */
  getServicePCFunctionEnabled(): boolean {
    return this.sharedSetting.servicePCFunctionEnabled;
  }

  /**
   * サービスPC機能の使用を更新します.
   * 
   * @param servicePCFunctionEnabled サービスPC機能の使用
   */
  setServicePCFunctionEnabled(servicePCFunctionEnabled: boolean) {
    this.sharedSetting.servicePCFunctionEnabled = servicePCFunctionEnabled;
  }

  /**
   * (AD車両テスト用)AD車両テスト機能の使用を返却します.
   * @returns AD車両テスト機能の使用
   */
   getAdTestFunctionEnabled(): boolean {
    return this.sharedSetting.adTestFunctionEnabled;
  }

  /**
   * (AD車両テスト用)AD車両テスト機能の使用を更新します.
   * @param adTestFunctionEnabled AD車両テスト機能の使用
   */
  setAdTestFunctionEnabled(adTestFunctionEnabled: boolean) {
    this.sharedSetting.adTestFunctionEnabled = adTestFunctionEnabled;
  }

  /**
   * SOCを返却します.
   * 
   * @returns SOC
   */
  getSoc(): number {
    return this.sharedSetting.soc;
  }

  /**
   * SOCを更新します.
   * 
   * @param soc SOC
   */
  setSoc(soc: number) {
    this.sharedSetting.soc = soc;
  }
}
