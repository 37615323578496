import * as Polyline from 'polyline';

export class PolylineUtil {
  /**
   * ポリラインを緯度経度のリストに変換します.
   *
   * @param polyline ポリライン
   * @returns 緯度経度のリスト
   */
  public static toLatLngs(polyline: string): number[][] {
    return Polyline.decode(polyline);
  }
  // 緯度経度リストからポリラインに変換
  public static toPolyline(latLngs: number[][]): string{
    return Polyline.encode(latLngs);
  }
}
