import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

import { RouteUpdateAccepted } from './models/route-update-accepted';
import { MqttPublishService } from './mqtt-publish.service';
import { MqttService } from './mqtt.service';

import { SharedSettingService } from '../shared-setting.service';
import { KeyboardStyle } from '@capacitor/keyboard';

@Injectable({
  providedIn: 'root'
})
export class RouteUpdateAcceptedMqttService {
  private publishClient: MqttService;

  private topic: string;

  constructor(
    private mqttPublishService: MqttPublishService,
    private sharedSettingService: SharedSettingService
  ) { }

  /**
   * 開始します.
   */
  async start() {
    if(!this.sharedSettingService.getServicePCFunctionEnabled()){
      // サービスPC機能がOFFの場合、AD車両トピックを設定する
      this.topic = this.sharedSettingService.getVehicle().vin + '/FMS' + environment.setting.setRouteAcceptedTopic;
    }else{
      this.topic = this.sharedSettingService.getVehicle().vin + environment.setting.setRouteAcceptedTopic;
    }
    this.publishClient = this.mqttPublishService.getMqttClient();
  }

  /**
   * 停止します.
   */
  async stop() {
    this.publishClient = null;
  }

  /**
   * トピックを取得します.
   */
  getTopic(): string {
    return this.topic;
  }

  /**
   * コネクションの状態を確認し、接続が切れている場合、再接続します.
   * 
   * @returns 接続状態
   */
  checkAndRetryConnect(): boolean {
    return true;
  }

  /**
   * メッセージを送信します.
   * 
   * @param message 送信メッセージ
   * @returns 送信結果
   */
  publishMessage(message: RouteUpdateAccepted): boolean {
    if (this.publishClient?.checkConnect()) {
      this.publishClient.sendTopic(JSON.stringify(this.convertTelemetry(message)), this.topic);

      return true;
    }

    return false;
  }

  /**
   * メッセージを作成します.
   * 
   * @param status status
   * @param requestIdDeNA requestIdDeNA
   * @param carVinDeNA carVinDeNA
   * @param polylineDeNA polylineDeNA
   * @returns メッセージ
   */
  createMessage(status: number, requestIdDeNA: string, carVinDeNA: string, polylineDeNA: string, stopId: string): RouteUpdateAccepted {
    return {
      status: status,
      result: {
        vin: carVinDeNA,
        polyline: polylineDeNA
      },
      request: {
        request_id: requestIdDeNA,
        vin: carVinDeNA,
        polyline: polylineDeNA,
        access_token: '',
        stop_id: stopId
      }
    };
  }

  /**
   * (AD車両テスト用)テレメトリ送信データを変更
   * サービスPC機能ONの場合"stop_id"未使用
   * サービスPC機能OFFかつAD車両テスト機能ONの場合"access_token"未使用
   * @param data データ
   * @returns 変更したデータ
   */
  private convertTelemetry(data: RouteUpdateAccepted): any{
    const sendData = {};
    const inRequestData = {};
    Object.keys(data).forEach((key)=>{
      if(key === "status" || key === "result"){
        sendData[key] = data[key];
      }else if(key === "request"){
        Object.keys(data["request"]).forEach((key)=>{
          if(this.sharedSettingService.getServicePCFunctionEnabled()){
            if(key !== "stop_id"){
              inRequestData[key] = data["request"][key];
            }
          }else if(this.sharedSettingService.getAdTestFunctionEnabled()){
            if(key !== "access_token"){
              inRequestData[key] = data["request"][key];
            }
          }
        })
        sendData[key] = inRequestData;
      }
    });
    return sendData;
  }
}
